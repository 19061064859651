type ComponentImport = {
  [key: string]: () => unknown;
}

const componentsToImport = [
  'AccommodationFeatures',
  'AirConditioning',
  'Bathrooms',
  'Bedrooms',
  'Bore',
  'CityViews',
  'Dam',
  'ElevatedOutlook',
  'FloorConstruction',
  'GeneralCondition',
  'Paddock',
  'Parking',
  'Pool',
  'PropertyFeatures',
  'RoofConstruction',
  'SecureParking',
  'TennisCourt',
  'WallConstruction',
  'WaterViews'
]

export const componentsImports = () => {
  const components: ComponentImport = {}

  componentsToImport.forEach((c: string) => {
    components[c] = () => import(`../FormFields/${c}.vue`)
  })

  return components
}
