









import { Component, Vue, Prop } from 'vue-property-decorator'
import PrimeVueTextarea from 'primevue/textarea'

@Component({
  components: {
    PrimeVueTextarea
  }
})
export default class InputTextarea extends Vue {
  @Prop({ required: true })
  public value!: string

  @Prop({ required: true })
  public fieldId!: string

  @Prop({ required: true })
  public placeholder!: string

  @Prop({ required: true })
  public label!: string

  @Prop({ required: false })
  public error: string | undefined

  @Prop({ required: false, default: 5 })
  public rows!: number

  onInput (newVal: string) {
    this.$emit('input', newVal)
  }
}
