var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form',{staticClass:"step-field-form"},[_c('h3',{staticClass:"step-section-title"},[_vm._v("Hobby Farm Details")]),_c('ValidationProvider',{attrs:{"name":"Use","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('InputText',{attrs:{"fieldId":"use","error":errors[0],"placeholder":"e.g. Grain crop, Orchard, Horticulture","label":"Use"},model:{value:(_vm.use),callback:function ($$v) {_vm.use=$$v},expression:"use"}})]}}])}),_c('GeneralCondition',{attrs:{"rules":""},model:{value:(_vm.generalCondition),callback:function ($$v) {_vm.generalCondition=$$v},expression:"generalCondition"}}),_c('Bedrooms',{attrs:{"rules":""},model:{value:(_vm.bedrooms),callback:function ($$v) {_vm.bedrooms=$$v},expression:"bedrooms"}}),_c('Bathrooms',{attrs:{"rules":""},model:{value:(_vm.bathrooms),callback:function ($$v) {_vm.bathrooms=$$v},expression:"bathrooms"}}),_c('ValidationProvider',{attrs:{"name":"Internal Floor Area (m²)","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('InputNumber',{attrs:{"fieldId":"floorArea","error":errors[0],"placeholder":"Enter internal floor area","suffix":"m²","label":"Internal Floor Area (m²)"},model:{value:(_vm.floorArea),callback:function ($$v) {_vm.floorArea=$$v},expression:"floorArea"}})]}}])}),_c('h3',{staticClass:"step-section-title"},[_vm._v("Construction Details")]),_c('FloorConstruction',{attrs:{"rules":""},model:{value:(_vm.floorConstruction),callback:function ($$v) {_vm.floorConstruction=$$v},expression:"floorConstruction"}}),_c('WallConstruction',{attrs:{"rules":""},model:{value:(_vm.wallConstruction),callback:function ($$v) {_vm.wallConstruction=$$v},expression:"wallConstruction"}}),_c('RoofConstruction',{attrs:{"rules":""},model:{value:(_vm.roofConstruction),callback:function ($$v) {_vm.roofConstruction=$$v},expression:"roofConstruction"}}),_c('ValidationProvider',{attrs:{"name":"Outbuildings","rules":"numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('InputNumber',{attrs:{"fieldId":"numberOfOutbuildings","error":errors[0],"showButtons":true,"placeholder":"Enter outbuildings","label":"Outbuildings"},model:{value:(_vm.numberOfOutbuildings),callback:function ($$v) {_vm.numberOfOutbuildings=$$v},expression:"numberOfOutbuildings"}})]}}])}),_c('h3',{staticClass:"step-section-title"},[_vm._v("Property Features")]),_c('PropertyFeatures',{attrs:{"featureType":"Rural"},model:{value:(_vm.propertyFeatures),callback:function ($$v) {_vm.propertyFeatures=$$v},expression:"propertyFeatures"}}),_vm._l((_vm.outbuildings),function(outbuilding,index){return _c('div',{key:index,staticClass:"step-field-form"},[_c('h3',{staticClass:"step-section-title"},[_vm._v("Rural Outbuilding "+_vm._s(index + 1))]),_c('ValidationProvider',{attrs:{"name":"Building Type/Name","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('InputText',{attrs:{"fieldId":'buildingType' + index,"error":errors[0],"placeholder":"Enter building type/name","label":"Building Type/Name"},model:{value:(outbuilding.buildingType),callback:function ($$v) {_vm.$set(outbuilding, "buildingType", $$v)},expression:"outbuilding.buildingType"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Building Use","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('InputText',{attrs:{"fieldId":'buildingUse' + index,"error":errors[0],"placeholder":"Enter building use","label":"Building Use"},model:{value:(outbuilding.buildingUse),callback:function ($$v) {_vm.$set(outbuilding, "buildingUse", $$v)},expression:"outbuilding.buildingUse"}})]}}],null,true)}),_c('GeneralCondition',{attrs:{"idSuffix":index},model:{value:(outbuilding.generalCondition),callback:function ($$v) {_vm.$set(outbuilding, "generalCondition", $$v)},expression:"outbuilding.generalCondition"}}),_c('ValidationProvider',{attrs:{"name":"Building Area","rules":"numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('InputNumber',{attrs:{"fieldId":'buildingArea' + index,"error":errors[0],"placeholder":"Enter building area","label":"Building Area"},model:{value:(outbuilding.buildingArea),callback:function ($$v) {_vm.$set(outbuilding, "buildingArea", $$v)},expression:"outbuilding.buildingArea"}})]}}],null,true)})],1)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }